import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  alpha,
  InputBase,
  styled,
  Grid,
  Tooltip,
  Avatar,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HeaderTab from "../header-tab/HeaderTab";
import { Logout, Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import { ContextHolder, useAuth, useAuthActions } from "@frontegg/react";
import ABAEngineLogo from "../../assets/logos/abaengine.png";
import { HeadersTabs, Tabs } from "../../constants/tabs";
import "./header.scss";
import { useSession, useUser, useDescope } from "@descope/react-sdk";
import { useSelector } from "react-redux";
import {RootState, useAppDispatch } from "../../redux/store";
import { getOrgInfo } from "../../redux/features/organization/organization-slice";
import CloseIcon from "@mui/icons-material/Close";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
  },
}));

export const Header: React.FC = () => {
  //#region Variable region
  const dispatch = useAppDispatch();

  //stores org logo
  const orgLogo = useSelector<RootState, string>(
    (state) => state.organizationSlice.orgData.orgLogo.url
  );

  //store open search
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openSearch, setOpenSearch] = useState<boolean>(false);

  //store active tabs
  const activeTab: string = localStorage.getItem("selectedTab") || "Home";

  //spread auth and user accessibility
  // const { isAuthenticated, user } = useAuth();
  const { isAuthenticated } = useSession();
  const { user } = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  //store selectedtabs
  const [selectedTab, setSelectedTab] = useState<string>(activeTab);

  // const [open, setOpen] = React.useState(false);

  //store open meanu value
  const [openMenu, setOpenMenu] = React.useState(false);

  //variable for navigation
  const navigate = useNavigate();

  //#endregion

  //#region method region

  //handle drawer open or not
  const handleDrawerOpen = () => {
    setOpenMenu(!openMenu);
  };

  //handle logout
  const { logout } = useDescope();
  // const logout = () => {
  //   const baseUrl = ContextHolder.getContext().baseUrl;
  //   window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  // };

  //#endregion

  //#region useEffect region

  //re-fetch set selected tab
  useEffect(() => {
    setSelectedTab("Home");
    dispatch(getOrgInfo());

    // if (user) {
    //   const parentTenant = user?.tenants?.filter(
    //     (tenant: any) => tenant.roles[0].key === "provider"
    //   );

    //   // if (parentTenant) {
    //   //   //console.log("reset active tenant as parent tenant");
    //   //   const tenantId = parentTenant[0].tenantId;
    //   //   if (tenantId !== user.tenantId) {
    //   //     switchTenant({
    //   //       tenantId,
    //   //       callback: (res, err) => {
    //   //         if (err) {
    //   //           console.log(err);
    //   //         }
    //   //       },
    //   //     });
    //   //   }
    //   // }
    // }
  }, []);
  //#endregion

  return (
    <Grid container>
      <AppBar
        position="static"
        style={{
          backgroundColor: "white",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Toolbar
          className={openMenu ? "mainHeader openMobileMenu" : "mainHeader"}
          style={{
            color: "black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box
              sx={{
                display: { xs: "block", lg: "none", xl: "none", md: "none" },
              }}
            >
              <IconButton
                className="mainHeader__toggle"
                size="large"
                edge="start"
                sx={{ mr: 2 }}
                style={{ background: "transform", color: "#000" }}
                onClick={handleDrawerOpen}
              >
                <Menu />
              </IconButton>
            </Box>

            <img
              alt="Loading"
              src={orgLogo ? orgLogo : ABAEngineLogo}
              className="mainHeader__logo"
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Box
              className="mobileSearch"
              onClick={() => setOpenSearch(!openSearch)}
            >
              <SearchIcon color="primary" />
            </Box>
            <Search
              className={
                openSearch === true
                  ? "search-block active-search"
                  : "search-block"
              }
            >
              <SearchIconWrapper className="search-block__icon">
                <SearchIcon color="primary" />
              </SearchIconWrapper>
              {openSearch && (
                <StyledInputBase
                  style={{ color: "black" }}
                  placeholder="Search keywords"
                />
              )}
              <StyledInputBase
                className="searchView-desk"
                style={{ color: "black" }}
                placeholder="Search keywords"
              />
            </Search>
            <Box className="overlay" onClick={handleDrawerOpen} />
            <Box
              display={"flex"}
              className={
                openMenu
                  ? "mainHeader__menu desk-menu open"
                  : "mainHeader__menu desk-menu"
              }
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                className="mobile-controls"
              >
                <div style={{ display: "flex" }}>
                  <IconButton
                    className="mainHeader__toggle"
                    size="large"
                    edge="start"
                    sx={{ mr: 2 }}
                    style={{
                      background: "transform",
                      color: "#000",
                      marginLeft: "26px",
                      height: "50px",
                    }}
                    onClick={handleDrawerOpen}
                  >
                    <Menu />
                  </IconButton>
                  <img
                    alt="Loading"
                    src={orgLogo ? orgLogo : ABAEngineLogo}
                    height={"50px"}
                    width={"auto"}
                  />
                </div>

                <CloseIcon
                  color="primary"
                  sx={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={handleDrawerOpen}
                />
              </Box>
              <HeaderTab
                onTabClick={(tab: Tabs) => {
                  setSelectedTab(tab.tabName);
                  handleDrawerOpen();
                  localStorage.setItem("selectedTab", tab.tabName);
                  navigate(`/${tab.tabName}`);
                }}
                selectedTab={selectedTab}
                tabs={HeadersTabs}
              />
            </Box>
            {isAuthenticated ? (
              <Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Box
                    sx={{ flexGrow: 0 }}
                    className="userBox"
                    alignItems={"center"}
                  >
                    <Tooltip
                      title="Open Profile settings"
                      className="userBox__image"
                    >
                      <Avatar alt="userProfile" src={user?.picture || ""} />
                    </Tooltip>
                    <Typography className="userBox__name" component="span">
                      {user?.name}
                    </Typography>
                  </Box>
                  <IconButton className="logout-icon" onClick={() => logout()}>
                    <Logout />
                  </IconButton>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default Header;
