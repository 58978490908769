import React from "react";
import { useLocation } from "react-router-dom";
import SurveyComponent from "./SurveyComponent";

const OnBoardingForm = () => {
  //allow to get current location
  const location = useLocation();
  
  return <SurveyComponent count={location.state.FormNo} />;
};

export default OnBoardingForm;
