import { Box, Button, Grid, TextareaAutosize } from "@mui/material";
import { AllUserMessage } from "../../../type/message";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import system from "../../../assets/images/images-png/setting.png";
import { dateToFromNowDaily } from "../../../helper/reverselist";
import { useState } from "react";
import { useUser } from "@descope/react-sdk";
import { PostCreateMessage } from "../../../redux/features/message/message-slice";
import { sortData } from "../../../constants/message";
import { useAppDispatch } from "../../../redux/store";

export interface InternalMessageProps {
  allMessages: AllUserMessage[];
  selectedUser: {
    id: string;
    name: string;
    type: "Provider" | "Client";
  };
}

const InternalMessage: React.FC<InternalMessageProps> = ({
  allMessages,
  selectedUser,
}) => {
  //#region variables region
  //user from Descope
  const dispatch = useAppDispatch();

  const { user } = useUser();
  //use to store messages
  const [message, setMesaage] = useState<string>("");

  //handle message error
  const [errorMessage, setErrorMessage] = useState<string>("");

  //#endregion

  //#region methods region

  //handles internal messages
  const internalMessageHandler = async () => {
    setErrorMessage("");
    if (!message) {
      setErrorMessage("Please enter notice text first");
      return;
    }

    await dispatch(
      PostCreateMessage({
        entityId: selectedUser.id,
        subject: "User Notice",
        message,
        whoCanView: ["admin", "parent", "employee"],
        type: "internal",
        from: "",
        entityType: selectedUser.type,
        messagetype: "internalCount",
      })
    );
    setMesaage(""); 
  };

  //#endregion

  return (
    <>
      <Grid item xs={16} md={16}>
        <span style={{ color: "#d32f2f" }}>{errorMessage}</span>
        <Box className="commentForm">
          <TextareaAutosize
            className="form-control"
            value={message}
            onChange={(e) => {
              setMesaage(e.target.value);
              setErrorMessage("");
            }}
            placeholder="Leave an internal notice..."
            minRows={2}
          />
          <Button variant="contained" onClick={internalMessageHandler}>
            Add Notice
          </Button>
        </Box>
      </Grid>
      <Grid className="scroll-bar" id="load-more">
        {allMessages.length > 0 ? (
          sortData(allMessages).map((item: AllUserMessage) => {
            return (
              <Box
                className={
                  !item.seenUserId.includes(user.userId)
                    ? "commentListItem generated light-blue-bg"
                    : "commentListItem generated"
                }
                key={item.id}
              >
                <Box className="compose-block">
                  <span className="commentListItem__user-img">
                    {!item.seenUserId.includes(user.userId) ? (
                      <>
                        <span className="active"></span>
                      </>
                    ) : (
                      ""
                    )}
                    <img
                      src={
                        item?.fromUser
                          ? item?.fromUser?.profilePictureUrl || ClientImg
                          : system
                      }
                      className="userSmallCircle"
                      alt=""
                    />
                  </span>
                  <div className="commentListItem__user-info">
                    <div className="compose-user-heading">
                      <h5 className="compose-user-name">
                        {!item.fromUser
                          ? "System Generated "
                          : item.fromUser?.firstName +
                            " " +
                            item.fromUser?.lastName}
                      </h5>
                      <h6 className="compose-user-time">
                        <span>{dateToFromNowDaily(item?.createdOn)}</span>
                      </h6>
                    </div>

                    <div
                      className="compose-user-comment"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {item?.message}
                    </div>
                  </div>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box>No Notice Found</Box>
        )}
      </Grid>
      {sortData(allMessages)?.length > 5 ? (
        <Box className="commentList-btn">
          <button
            onClick={() => {
              document.getElementById("load-more")?.scrollBy(0, 100);
            }}
          >
            Load More
          </button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default InternalMessage;
