import React from "react";
import { useSession } from "@descope/react-sdk";
import { Navigate } from "react-router-dom";

const PublicRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated } = useSession();

  return isAuthenticated ? <Navigate to={"/"} /> : children;
};
export default PublicRoute;
