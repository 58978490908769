import React, { useMemo } from "react";
import { useSession, useUser, useDescope } from "@descope/react-sdk";
import Header from "../component/header/Header";
import { Navigate } from "react-router-dom";
import { getRolesForTenant } from "../utils/getRolesForTenant";

const PrivateRoute: React.FC<{ children: any }> = ({ children }) => {
  const { isAuthenticated } = useSession();
  const { user } = useUser();
  const { logout } = useDescope();

  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  if (user) {
    //find tenant that has roles key as Provider
    // const providerTenant = user?.userTenants?.filter((tenant) =>
    //   tenant.roleNames?.includes("Provider")
    // );
    // if (!providerTenant || providerTenant?.length === 0) {
    if (!(currentRoles.includes("Owner") ||
      currentRoles.includes("Employee") ||
      currentRoles.includes("Admin"))) {
      return (
        <div>
          <span>Only Employee has permission to use employee portal</span>
          <button onClick={() => logout()}>Logout</button>
        </div>
      );
    }
  }

  return isAuthenticated ? (
    <div>
      <Header />
      {children}
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};
export default PrivateRoute;
