import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import { getEmployeeScheduleSettings } from "../../redux/features/providerSlice";
import { IEmployeeScheduleSettings } from "../../type/schedule";
import ScheduleHeatmap from "./ScheduleHeatmap";
import ScheduleCalendar from "./ScheduleCalendar";
import "./scheduleCalendar.scss";
import { IEmployeePortalData } from "../../type/providerDetails";
import { IClientListDetails } from "../../type/client-info";
import ClientImg from "../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../assets/images/images-svg/provider-large-vertical.svg";

const ScheduleNew = () => {
  const dispatch = useAppDispatch();

  //stores selected Tab
  const [selectedTab, setSelectedTab] = useState<string>("Employees");

  //stores provider details
  const providerDetails = useSelector<RootState, IEmployeePortalData>(
    (state) => state.providerSlice.providerProfile
  );

  //stores list for schedule calendar
  const { clientList, providerList } = useAppSelector(
    (state) => state.listDataSlice
  );

  //for storing current employee
  const provider = providerList.filter(
    (item) => item.employmentDetails.id === providerDetails.employmentDetails.id
  );

  //stores selected Clients
  const [selectedClients, setSelectedClients] = useState<IClientListDetails[]>(
    []
  );

  //stores selected providers
  const [selectedProviders, setSelectedProviders] =
    useState<IEmployeePortalData[]>([...provider]);

  //stores Schedule settings
  const employeeSettings = useSelector<RootState, IEmployeeScheduleSettings>(
    (state) => state.providerSlice.employeeScheduleSetting
  );

  //handles client selection
  const handleSelectClients = (item: IClientListDetails) => {
    const isExist = selectedClients.some((ele) => ele === item);
    if (isExist) {
      const data = selectedClients.filter((ele) => ele !== item);
      setSelectedClients(data);
    } else {
      setSelectedClients([...selectedClients, item]);
    }
  };

  //handles provider selection
  const handleSelectProvider = (item: IEmployeePortalData) => {
    const isExist = selectedProviders.some((ele) => ele === item);
    if (isExist) {
      const data = selectedProviders.filter((ele) => ele !== item);
      setSelectedProviders(data);
    } else {
      setSelectedProviders([...selectedProviders, item]);
    }
  };

  //useEffect
  useEffect(() => {
    dispatch(getEmployeeScheduleSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid margin={4}>
      {employeeSettings.allowEmployeeToSeeAvailablity && (
        <ScheduleHeatmap
          isEdit={employeeSettings.allowEmployeeToUpdateAvailablity}
          providerDetails={providerDetails}
        />
      )}
      <Grid className="mapWrapper">
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} md={4}>
            <Box display={"flex"}>
              <ul className="innerTabs">
                <li className="innerTabs__item">
                  <button
                    onClick={() => {
                      setSelectedTab("Clients");
                    }}
                    className={selectedTab === "Clients" ? "active" : ""}
                  >
                    Clients
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => {
                      setSelectedTab("Employees");
                    }}
                    className={selectedTab === "Employees" ? "active" : ""}
                  >
                    Employees
                  </button>
                </li>
              </ul>
            </Box>
            <div className="mapUserList">
              {selectedTab === "Clients" &&
                clientList.map((item) => {
                  return (
                    <div
                      className={
                        selectedClients.some((ele) => ele === item)
                          ? "fromGroup-chip-active"
                          : "userMapCart "
                      }
                      key={item?.clientBasicDetails?.id}
                      onClick={() => handleSelectClients(item)}
                    >
                      <div className="cardLevelBlock">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "space-between",
                                  marginRight: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <span className="userMapCart__img">
                                  <img
                                    src={
                                      item?.clientBasicDetails?.clientProfile
                                        ?.url || ClientImg
                                    }
                                    className="userSmallCircle"
                                    alt=""
                                  />
                                </span>
                              </th>
                              <th>
                                <div className="userMapCart__name">
                                  {item?.clientBasicDetails?.childFirstName}{" "}
                                  {item?.clientBasicDetails?.childLastName}
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  );
                })}
              {selectedTab === "Employees" &&
                providerList.map((item) => {
                  return (
                    <div
                      className={
                        selectedProviders.some((ele) => ele === item)
                          ? "fromGroup-chip-active"
                          : "userMapCart "
                      }
                      key={item?.employmentDetails?.id}
                      onClick={() => handleSelectProvider(item)}
                    >
                      <div className="cardLevelBlock">
                        <table>
                          <thead>
                            <tr>
                              <th
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "space-between",
                                  marginRight: "16px",
                                  alignItems: "center",
                                }}
                              >
                                <span className="userMapCart__img">
                                  <img
                                    src={
                                      item?.employmentDetails?.providerProfile
                                        ?.url || ProviderImg
                                    }
                                    className="userSmallCircle"
                                    alt=""
                                  />
                                </span>
                              </th>
                              <th>
                                <div className="userMapCart__name">
                                  {/* <strong> */}
                                  {item?.employmentDetails.firstName}{" "}
                                  {item?.employmentDetails.lastName}
                                </div>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={16} md={12}>
            <ScheduleCalendar
              selectedClients={selectedClients}
              selectedProviders={selectedProviders}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleNew;
