class GlobalAppUrls {
  public Client = {
    Home: "/home",
    Schedule: "/schedule",
    Document: "/documents",
    Messages: "/messages",
    Tasks:"/tasks",
  };

  public Server = {
    Account: {
      Login: "/api/v1/users/login",
      Logout: "/api/v1/users/logout",
    },
  };
}

export const AppUrls = new GlobalAppUrls();
