import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Loader from "../../layouts/loader/Loader";

interface SurveyCompleteProps {
  loadingStatus: string;
  errorMessage: string;
}

const SurveyComplete = ({
  loadingStatus,
  errorMessage,
}: SurveyCompleteProps) => {
  //used for navigation
  const navigate = useNavigate();

  return (
    <div className="surveyTest">
      {loadingStatus === "Success" && (
        <div className="TestInner">
          <Typography className="testTitle">
            Thank you for completing the form.
          </Typography>
          <div onClick={() => navigate(-1)}>Return to Home</div>
        </div>
      )}

      {loadingStatus === "Loading" && (
        <div className="TestInner">
          <Typography className="testTitle">
            We are loading your form, please wait for completion...
          </Typography>
          <Loader isShow={true} />
        </div>
      )}

      {loadingStatus === "Failed" && (
        <div className="TestInner">
          {errorMessage?.length > 0 ? (
            <Typography className="testTitle">
              Loading Form Failed: {errorMessage}. Please try it again.
            </Typography>
          ) : (
            <Typography className="testTitle">
              Loading Form Failed. Please try it again.
            </Typography>
          )}
        </div>
      )}
      {/* <div className="TestInner">
        <Typography className="testTitle">
          Thank you for completing the form.
        </Typography>
        <div onClick={() => navigate(-1)}>Return to Home</div>
      </div> */}
    </div>
  );
};

export default SurveyComplete;
