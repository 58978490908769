import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import MessageType from "./MessageType";
import MessageList from "./MessageList";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ComposeMessage from "./ComposeMessage";

const MessageField: React.FC<{
  selectedUser: {
    id: string;
    name: string;
    type: "Provider" | "Client";
  };
}> = ({ selectedUser }) => {
  const [selectedCommunicationTab, setSelectedCommunicationTab] =
    useState("Internal");
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Grid item xs={16} md={12}>
      <MessageType
        selectedCommunicationTab={selectedCommunicationTab}
        setSelectedCommunicationTab={setSelectedCommunicationTab}
        setOpenDialog={setOpenDialog}
      />
      <MessageList
        selectedUser={selectedUser}
        selectedCommunicationTab={selectedCommunicationTab}
      />
      <Dialog open={openDialog} className="largeModel largeModel--edit">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Compose Message
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>
        <ComposeMessage
          setOpenDialog={setOpenDialog}
          selectedUser={selectedUser}
        />
      </Dialog>
    </Grid>
  );
};

export default MessageField;
