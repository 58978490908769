import { Grid } from "@mui/material";
import { AllUserMessage } from "../../../type/message";
import { useState, useEffect, useCallback } from "react";
import ExternalMessage from "./ExternalMessage";
import InternalMessage from "./InternalMessage";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { useUser } from "@descope/react-sdk";
import {
  GetMessageByEntityId,
  UpdateMessageStatusById,
} from "../../../redux/features/message/message-slice";

interface IMessageList {
  selectedCommunicationTab: string;
  selectedUser: {
    id: string;
    name: string;
    type: "Provider" | "Client";
  };
}

const MessageList: React.FC<IMessageList> = ({
  selectedCommunicationTab,
  selectedUser,
}) => {
  const dispatch = useAppDispatch();

  const [{ internalMessage, externalMessage }, setMessageData] = useState<{
    internalMessage: AllUserMessage[];
    externalMessage: AllUserMessage[];
  }>({
    internalMessage: [],
    externalMessage: [],
  });

  const {
    user: { userId },
  } = useUser();

  const { allUserMessage } = useAppSelector(
    (state) => state.messageSlice.allMessageData
  );

  const handleReadInternalMessage = useCallback(() => {
    if (selectedCommunicationTab !== "Internal") return;
    if (internalMessage.length > 0) {
      let activeMessageIds: string[] = [];
      internalMessage.forEach((item) => {
        if (!item.seenUserId.includes(userId)) {
          activeMessageIds.push(item.id);
        }
        return activeMessageIds;
      });
      if (activeMessageIds.length > 0) {
        dispatch(
          UpdateMessageStatusById({
            id: activeMessageIds,
            type: "internalCount",
            userId: "",
          })
        );
      }
    }
  }, [selectedCommunicationTab, selectedUser.id]);

  useEffect(() => {
    let internalArr: AllUserMessage[] = [];
    let externalArr: AllUserMessage[] = [];

    allUserMessage.forEach((item) => {
      if (item.type === "internal") {
        internalArr.push(item);
      }
      if (item.type === "external") {
        externalArr.push(item);
      }
    });

    if (internalArr.length > 0) {
      let arr: string[] = [];
      internalArr.map((item) => {
        return arr.push(item.id);
      });
    }
    setMessageData({
      internalMessage: internalArr,
      externalMessage: externalArr,
    });
  }, [allUserMessage]);

  useEffect(() => handleReadInternalMessage(), [handleReadInternalMessage]);
  useEffect(() => {
    dispatch(GetMessageByEntityId({ entityId: selectedUser.id }));
  }, [selectedUser.id]);
  return (
    <Grid item xs={16} md={16}>
      {selectedCommunicationTab === "Internal" && (
        <InternalMessage
          selectedUser={selectedUser}
          allMessages={internalMessage}
        />
      )}
      {selectedCommunicationTab === "External" && (
        <ExternalMessage
          selectedUser={selectedUser}
          allMessages={externalMessage}
        />
      )}
    </Grid>
  );
};

export default MessageList;
