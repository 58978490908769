import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { SurveyPDF } from "survey-pdf";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";

import "survey-core/defaultV2.min.css";
import "./SurveyComponent.css";

import { themeJson } from "./theme";
import SurveyComplete from "./SurveyComplete";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setResponseValue } from "../../redux/features/api-response";
import formService from "../../service/form.service";
import { IOnboardingForm, IResultfinal } from "../../type/form";

interface ISurveyform {
  count: number;
}

const SurveyComponent: React.FC<ISurveyform> = ({ count }) => {
  //dispatch
  const dispatch = useAppDispatch();

  //used for redirecting to completeSurvey Page
  // const [saved, setSaved] = useState<boolean>(false);

  const [loadingStatus, setLoadingStatus] = useState<string>("Pending");
  const [messageError, setMessageError] = useState<string>("");

  //used to handle onboarding form
  const OnboardingForm = useAppSelector(
    (state) => state.providerOnboardingFormSlice.onBoardingform
  );

  //stores survey data
  const survey = new Model(OnboardingForm?.form?.formSchema);

  //store running status
  const [isRunning, setIsRunning] = React.useState(true);

  // A variable that will store files until the survey is completed
  const [fileStorage, setFileStorage] = useState<any>({}); //questionName -> files


  // used for navigation
  const navigate = useNavigate();
  // You can delete the line below if you do not use a customized theme
  survey.applyTheme(themeJson);

  survey.onUploadFiles.add((sender, options) => {
    // console.log("calling onUploadFiles", fileStorage, options);
    // Add files to the temporary storage
    let tempFileStorage = fileStorage;

    //lookup by question name
    if (tempFileStorage && tempFileStorage[options.name] !== undefined) {
      tempFileStorage[options.name] = [
        ...tempFileStorage[options.name],
        ...options.files,
      ];
    } else {
      tempFileStorage[options.name] = options.files;
    }
    setFileStorage(tempFileStorage);
    // console.log("fileStorage after set:", fileStorage, tempFileStorage);

    // Load file previews
    const content: any[] = [];
    options.files.forEach((file) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        content.push({
          name: file.name,
          type: file.type,
          content: fileReader.result,
          file: file,
        });
        if (content.length === options.files.length) {
          // Return a file for preview as a { file, content } object
          options.callback(
            content.map((fileContent) => {
              return {
                file: fileContent.file,
                content: fileContent.content,
              };
            })
          );
        }
      };
      fileReader.readAsDataURL(file);
    });
  });

  // // Handles file removal
  survey.onClearFiles.add((_, options) => {
    // Empty the temporary file storage if "Clear All" is clicked
    let tempFileStorage = fileStorage;

    if (options.fileName === null && tempFileStorage) {
      tempFileStorage[options.name] = [];
      options.callback("success");
      setFileStorage(tempFileStorage);
      return;
    }

    // Remove an individual file
    const tempFiles = tempFileStorage[options.name];
    if (!!tempFiles) {
      const fileInfoToRemove = tempFiles.filter(
        (file: any) => file.name === options.fileName
      )[0];
      if (fileInfoToRemove) {
        const index = tempFiles.indexOf(fileInfoToRemove);
        tempFiles.splice(index, 1);
      }
    }
    options.callback("success");
  });

  survey.onComplete.add((sender, options) => {
    const resultData = [];

    saveSurveyData(survey, true);
    for (const key in survey.data) {
      const question = survey.getQuestionByName(key);
      if (!!question) {
        const item = {
          name: key,
          value: question.value,
          title: question.displayValue,
          displayValue: question.displayValue,
        };
        resultData.push(item);
      }
    }

    // setSaved(true);
  });

  // handle close assessment
  const closeAssessment = () => {
    navigate(-1);
  };

  //#region saveResult region

  // save survey Data
  const saveSurveyData = async (survey: any, isComplete: boolean) => {
    setLoadingStatus("Loading");
    setMessageError("");
    const previewsData: any = OnboardingForm?.formData;
    const data = survey.data;

    // Create a new object with the first two key-value pairs removed
    var newObject: any = {};
    if (previewsData) {
      var keys = Object.keys(previewsData);
      for (var i = 2; i < keys.length; i++) {
        var key = keys[i];
        newObject[key] = previewsData[key];
      }
    }

    data.pageNo = survey.currentPageNo;
    const latestData = { ...newObject, ...data };
    try {
      const { status, message } = await formService.updateFormresult(
        OnboardingForm?.id,
        { result: latestData, isComplete }
      );
      if (status) {
        console.log("show success");
        // options.showSaveSuccess();
        setLoadingStatus("Success");
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      } else {
        // options.showSaveError(message);
        setLoadingStatus("Failed");
        setMessageError(message);
        dispatch(setResponseValue({ name: "error", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      console.log("error:", e);
      setLoadingStatus("Failed");
      setMessageError(e?.message);
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }

    // await dispatch(
    //   UpdateFormResult({
    //     id: OnboardingForm.id,
    //     result: latestData,
    //     isComplete: isComplete,
    //   })
    // );
  };

  // Save survey results
  survey.onPartialSend.add((survey) => {
    saveSurveyData(survey, false);
  });

  //partial form
  if (OnboardingForm?.formData) {
    const data: IResultfinal = OnboardingForm?.formData;
    survey.data = OnboardingForm?.formData;
    if (data?.pageNo) {
      survey.currentPageNo = data?.pageNo;
    }
  }

  //#endregion

  //show Progress bar
  survey.showProgressBar = "Top";

  // Define a function to apply styles to the "Next" button
  function styleNextButton(sender: any, options: any) {
    // Check if your condition is met (for example, if a specific question is answered a certain way)
    var nextButton = document.getElementById("sv-nav-next");

    if (
      survey.currentPage.jsonObj?.name === "StartPage" ||
      survey.currentPage.jsonObj?.name === "page1"
    ) {
      // Check if the "Next" button exists
      if (nextButton) {
        // Add custom CSS class to the "Next" button
        nextButton.classList.add("sv-nav-next");
      }
    }
  }

  /*   survey.questionsOnPageMode = "singlePage";
  survey.mode = "display"; */
  //   "questionsOnPageMode": "singlePage",
  // "mode": "display",

  // Define a function to remove styles from the "Next" button
  function removeStylesFromNextButton(sender: any, options: any) {
    // Find the "Next" button within the current question
    var nextButton = document.getElementById("sv-nav-next");
    // Check if the "Next" button exists
    if (nextButton) {
      // Remove the custom CSS class from the "Next" button
      nextButton.classList.remove("sv-nav-next");
    }
  }
  let Count: number = 0;

  //handle percent progress result
  const progressResult = (item: number): number => {
    let val = 15;
    let multiplyer = 0;

    let result =
      item < val
        ? val * multiplyer
        : item <= val * (multiplyer + 2)
        ? val
        : item <= val * (multiplyer + 3)
        ? val * (multiplyer + 2)
        : item <= val * (multiplyer + 4)
        ? val * (multiplyer + 3)
        : item <= val * (multiplyer + 5)
        ? val * (multiplyer + 4)
        : item <= val * (multiplyer + 6)
        ? val * (multiplyer + 5)
        : item <= val * (multiplyer + 7) - 5
        ? val * (multiplyer + 6)
        : 100;
    return result;
  };

  //addprogress bar
  function addProgressBar(sender: any, options: any) {
    var progressBar = document.getElementsByClassName("sd-progress__text");
    // Loop through the selected elements and change their content
    if (progressBar.length) {
      const percentageBar = `${
        (survey.currentPageNo / survey.PageCount) * 100
      }`;
      let result = parseInt(percentageBar);

      for (let i = 0; i < progressBar.length; i++) {
        progressBar[i].textContent = `${progressResult(result)}${" "}${"%"}`;
      }
    }
  }

  survey.onStarted.add(() => {
    setIsRunning(true);
  });
  survey.onAfterRenderQuestion.add(styleNextButton);
  survey.onAfterRenderQuestion.add(addProgressBar);
  survey.data = {
    "nps-score": 9,
    "promoter-features": ["performance", "ui"],
  };

  survey.onFocusInQuestion.add((sender, options) => {
    //Added on closeButton
    let closeButton = document.getElementById("clsBtn") as HTMLElement;
    if (closeButton) {
      closeButton.onclick = () => {
        savePdf();
        saveSurveyData(survey, false);
      };
    }
  });

  //#region page render region
  survey.pageNextText = "Next";

  survey.showPrevButton = false;

  let result: number = 0;
  let Counter: number = 0;
  let reserve: number = 0;
  let arrtest: number[] = [];

  //handle per page render
  survey.onCurrentPageChanged.add((_, options) => {
    let accessValue: boolean = false;

    if (result < survey.currentPageNo) {
      result = survey.currentPageNo;
      arrtest.push(survey.currentPageNo);
      arrtest.sort((a, b) => a - b);
      Counter = Counter + 1;
      if (Counter === 1) reserve = survey.currentPageNo;
      accessValue = false;
    } else {
      result = survey.currentPageNo;
      arrtest.pop();
      accessValue = true;
      Counter = Counter - 1;
    }

    if (options.newCurrentPage.visibleIndex !== 0) {
      survey.onAfterRenderQuestion.add(removeStylesFromNextButton);
    }

    if (!accessValue) {
      if (survey.currentPageNo !== 0) {
        survey.showPrevButton = true;
        // if(arrtest.length === 2) survey.showPrevButton = false;
      } else {
        survey.showPrevButton = false;
      }
    } else {
      if (Counter !== 2) {
        survey.showPrevButton = true;
      } else {
        survey.showPrevButton = false;
      }
    }
  });
  //#endregion

  //#region Pdf region

  //Create Pdf model
  function createSurveyPdfModel(surveyModel: any) {
    let pdfWidth =
      !!surveyModel && surveyModel.pdfWidth ? surveyModel.pdfWidth : 210;
    let pdfHeight =
      !!surveyModel && surveyModel.pdfHeight ? surveyModel.pdfHeight : 297;
    let options = {
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10,
      },

      format: [pdfWidth, pdfHeight],
    };

    const surveyPDF = new SurveyPDF(OnboardingForm?.form?.formSchema, options);
    if (surveyModel) {
      surveyPDF.data = surveyModel.data;
    }

    return surveyPDF;
  }

  //save survey pdf
  function saveSurveyToPdf(filename: any, surveyModel: any) {
    // createSurveyPdfModel(surveyModel).save(filename);
  }

  //used to save pdf
  const savePdf = function () {
    saveSurveyToPdf("surveyResult.pdf", survey);
  };

  //handle style of pdf button
  const btnStyle = {
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "20px",
  };

  //#endregion

  //#region closeButton region

  const renderButton = (
    text: string,
    func: any,
    canRender: boolean,
    ids: string
  ) => {
    if (!canRender) return undefined;

    return (
      <button className="navigation-button" id={ids} onClick={func}>
        {text}
      </button>
    );
  };

  const renderNavExternalNavigation = () => {
    if (!isRunning) return undefined;
    return (
      <div className="navigation-block topNavBlock">
        <div className="navigation-progress-container">
          <div className="navigation-buttons-container">
            {renderButton("Save Form", closeAssessment, true, "clsBtn")}
          </div>
        </div>
      </div>
    );
  };

  //#endregion

  //region useEffect region

  useEffect(() => {
    if (OnboardingForm?.formData !== undefined) {
      survey.data = OnboardingForm?.formData;
    }
  }, [OnboardingForm]);

  //#endregion

  return (
    <>
      {loadingStatus !== "Pending" ? (
        <SurveyComplete
          loadingStatus={loadingStatus}
          errorMessage={messageError}
        />
      ) : (
        <>
          {renderNavExternalNavigation()}
          <Box bgcolor={"#f3f3f3"} className="surveyFormBlock">
            <Survey model={survey} />
            {/* <div className="surveyFormBlock-btn">
          <button
            className={"sd-btn"}
            id={"pdf-preview"}
            style={btnStyle}
            onClick={savePdf}
          >
            Save as PDF
          </button>
        </div> */}
          </Box>
        </>
      )}
    </>
  );
};

export default SurveyComponent;
